import { createI18n } from 'vue-i18n'
import { LOCALE } from '@/config'
const extractFileName = (key: string) => key.split('/').pop()!.split('.')[0]

//eslint-disable-next-line
const localesGlobal = import.meta.glob<{ default: any }>('@/plugins/language/locales/*.json', { eager: true })

//eslint-disable-next-line
const localesTheme = import.meta.glob<{ default: any }>('@theme/locales/*.json', { eager: true })
const arrayTheme = Object.entries(localesTheme).map(([key, value]) => [extractFileName(key), value.default])
const messagesGlobal = Object.fromEntries(
  Object.entries(localesGlobal).map(([key, value]) => [extractFileName(key), value.default]),
)

const themeMessages = arrayTheme.reduce((acc, [_, value]) => {
  return { ...acc, ...value }
}, [])

//eslint-disable-next-line
function deepMerge(target: Record<string, any>, source: Record<string, any>): Record<string, any> {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]))
    }
  }
  Object.assign(target || {}, source)
  return target
}
const messages = { locale: deepMerge(messagesGlobal[LOCALE], themeMessages), ...messagesGlobal }
export const i18n = createI18n({
  locale: 'locale',
  fallbackLocale: 'en',
  messages,
  missing: () => {
    return ''
  },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
})
