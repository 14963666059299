import { Upload } from '@/interfaces/upload'
import { WorkflowState } from '@/interfaces/workflowState'
import { JSONLD } from '@interfaces/jsonld'

export class Content extends JSONLD {
  name: string = ''
  type?: string
  collection?: string
  parts: ContentPart[] = []
  workflowState?: WorkflowState
  metaData?: Record<string, string>
  createdAt: Date = new Date()
  history?: ContentHistory[] = []
  tempComment?: string

  lastChanges?: Date

  constructor(data?: Partial<Content>) {
    super('Content')
    Object.assign(this, data)
  }
}

export class ContentPart extends JSONLD {
  fieldName: string = ''
  value?: string | Upload | string[]
  oldValue?: string
  name?: string

  createdAt: Date = new Date()

  constructor(data?: Partial<ContentPart>) {
    super('Part')
    Object.assign(this, data)
  }
}
export class ContentHistory extends JSONLD {
  place: string = ''
  transition: string = ''
  user: string = ''
  comment: string = ''
  date: Date = new Date()
  userName: string = ''

  constructor(data?: Partial<ContentHistory>) {
    super('ContentHistory')
    Object.assign(this, data)
  }
}
