import { CropBox, Visualizations } from '@/interfaces/types'
import { JSONLD } from '@interfaces/jsonld'

export class ContentType extends JSONLD {
  name: string = ''
  type: string = 'default'
  icon: string = 'ri-file-line'
  description: string = ''
  workflow: string = ''
  template: string = ''
  visualization: Visualizations = 'editor'
  parts: Record<string, Part> = {}

  constructor(data?: Partial<ContentType>) {
    super('ContentType')
    Object.assign(this, data)
  }
}

export type PartTypes = 'html' | 'image' | 'ppt' | 'video'
export type Tag = 'ul' | 'em' | 'p' | 'h1' | 'h2' | 'h3' | 'strong' | 'em' | 'sub' | 'sup'
export interface Part {
  required: boolean
  type: PartTypes
  label: string
  placeholder: string
  allowed_tags: Tag[]
  file_types: string[]
  crop_box: CropBox
}
