import { UserGroup } from '@/interfaces/userGroup'
import { JSONLD } from '@interfaces/jsonld'

export class ParticipantInvitation extends JSONLD {
  email: string = ''
  roles: string[] = []
  firstName: string = ''
  lastName: string = ''
  department: string = ''
  needsPasswordChange: boolean = false
  userGroups: UserGroup[] = []
  totp: boolean = false

  constructor(data: ParticipantInvitation) {
    super('Participant')
    Object.assign(this, data)
  }
}
