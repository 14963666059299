import { ContentCollection } from '@/interfaces/contentCollection'
import { ApiResponse, JSONLD } from '@/interfaces/jsonld'
// import { objectFactory } from '@/interfaces/helpers'
import { Participant } from '@/interfaces/participant'
import { ProjectDeadline } from '@/interfaces/projectDeadline'
// import { User } from '@/interfaces/user'
// import { ApiResponse, ApiResponses, JSONLD } from '@interfaces/jsonld'

export class Project extends JSONLD {
  startDate: string = new Date().toISOString()
  endDate: string = new Date().toISOString()
  deadlines: ProjectDeadline[] = []
  name: string = ''
  contentCollections: (ContentCollection | string)[] = []
  participants: Participant[] = []
  //eslint-disable-next-line
  metaData: Record<string, any> = {}
  archived: boolean = false

  constructor(data?: ApiResponse<Project>) {
    super('Project')
    Object.assign(this, data)
    this.metaData = Array.isArray(data?.metaData) ? {} : data?.metaData || {}
  }

  // async getPaticipantRoles(user: User): Promise<string[]> {
  //   await this.resolveParticipants()

  //   this.participants.forEach(participant => {
  //     if (typeof participant === 'string') throw new Error('Participants not resolved')
  //     if (participant.user === user) {
  //       return participant.roles
  //     }
  //   })
  //   return []
  // }
  // resolveParticipants(): void {
  //   if (this.participants.length === 0) {
  //     return
  //   }
  //   if (typeof this.participants[0] === 'string') {
  //     api<ApiResponses<Participant>>({ id: `${this['@id']}/participants` }).then(
  //       (response: ApiResponses<Participant>) => {
  //         this.participants = objectFactory<Participant>(response)
  //       },
  //     )
  //   }
  // }
  // resolveContentCollections(): void {
  //   if (this.contentCollections.length === 0) {
  //     return
  //   }
  //   if (typeof this.contentCollections[0] === 'string') {
  //     api<ApiResponses<ContentCollection>>({ id: `${this['@id']}/contentCollections` }).then(
  //       (response: ApiResponses<ContentCollection>) => {
  //         this.contentCollections = objectFactory<ContentCollection>(response)
  //       },
  //     )
  //   }
  // }
}
