import { Types } from '@/interfaces/types'

export class JSONLD {
  '@context'?: string
  '@type': Types = 'Unknown'
  '@id': string = ''
  id: number | string = -1
  constructor(type: Types) {
    this['@type'] = type
  }
}

export interface ApiResponses<T extends JSONLD> extends JSONLD {
  'hydra:totalItems': number
  'hydra:member': ApiResponse<T>[]
}

export type ApiResponse<T extends JSONLD> = JSONLD & T
