import { UserShort } from '@interfaces/user'
import { JSONLD } from '@/interfaces/jsonld'
export class Notification extends JSONLD {
  isRead?: boolean
  subject?: string
  message?: string
  link?: string
  time?: Date
  from?: UserShort
  icon?: string
  constructor(data: Partial<Notification>) {
    super('Notification')
    Object.assign(this, data)
  }
}
