import type { App } from 'vue'

import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components/VBtn'
import defaults from './defaults'
import { icons } from './icons'
import { themes } from '@theme/themes'

// Styles

import '@core/scss/template/libs/vuetify/index.scss'
import 'vuetify/styles'
import { VDateInput } from 'vuetify/labs/VDateInput'

export default function (app: App) {
  const vuetify = createVuetify({
    components: { VDateInput },
    aliases: {
      IconBtn: VBtn,
    },
    defaults,
    icons,
    theme: {
      defaultTheme: 'light',
      themes,
    },
    display: { thresholds: { sm: 600, md: 800, lg: 1280, xl: 1585, xxl: 1585 } },
  })

  app.use(vuetify)
}
