<script setup lang="ts">
import { DEBUG_MENU } from '@/config'
import { VSonner } from 'vuetify-sonner'
import tdkIcon from '@/images/icons/favicon-idworks.png'
let iconPath = tdkIcon
onMounted(() => {
  //eslint-disable-next-line
  const icons = import.meta.glob<{ default: any }>('@theme/images/icons/*.png', { eager: true })
  if (Object.keys(icons).length > 0) iconPath = Object.keys(icons)[0]
  const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement | null
  if (favicon) {
    favicon.href = icons[iconPath].default
  }
})
</script>
<template>
  <VApp class="position-relative">
    <div v-if="DEBUG_MENU" class="position-absolute top-0 left-0 ma-1" style="opacity: 0.4; z-index: 10000">
      <DebugMenu />
    </div>
    <div>
      <RouterView />
    </div>
    <VSonner expand position="bottom-left" />
  </VApp>
</template>
,
