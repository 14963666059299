import type { GenericErrors, SubmissionErrors, AllErrors } from '@interfaces/error'

class GenericError<T extends AllErrors> extends Error {
  private readonly _errors: T
  constructor(errors?: T) {
    const message = errors?.error || errors?.['hydra:description'] || errors?.message || 'Generic Error'
    super(message)
    this._errors = errors || ({} as T)
  }
  public get errors(): T {
    return this._errors
  }
}

export class SubmissionError extends GenericError<SubmissionErrors> {
  constructor(errors?: SubmissionErrors) {
    super({ ...errors, message: 'Submit Validation Failed' })
  }
}
export class OtherError extends GenericError<GenericErrors> {
  constructor(errors?: GenericErrors) {
    super({ ...errors, message: 'Other Error' })
  }
}

export class NotFoundError extends GenericError<GenericErrors> {
  constructor(errors?: GenericErrors) {
    super({ ...errors, message: 'Not Found' })
  }
}

export class InternalServerError extends GenericError<GenericErrors> {
  constructor(errors?: GenericErrors) {
    super({ ...errors, message: 'Internal Server Error' })
  }
}

export class UnauthorizedError extends GenericError<GenericErrors> {
  constructor(errors?: GenericErrors) {
    super({ ...errors, message: 'Unauthorized' })
  }
}

export class TwoFactorError extends GenericError<GenericErrors> {
  constructor(errors?: GenericErrors) {
    super({ ...errors, message: 'Two Factor Required' })
  }
}

export class WrongCredentialsError extends GenericError<GenericErrors> {
  constructor(errors?: GenericErrors) {
    super({ ...errors, message: 'Wrong Username or Password' })
  }
}

export class BadRequestError extends GenericError<GenericErrors> {
  constructor(errors?: GenericErrors) {
    super({ ...errors, message: 'Bad Request' })
  }
}
