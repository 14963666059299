import { ApiResponse, JSONLD } from '@interfaces/jsonld'

export class ProjectDeadlineContentTypes extends JSONLD {
  options: Record<string, string> = {}

  constructor(data?: ApiResponse<ProjectDeadlineContentTypes>) {
    super('ProjectDeadlineContentTypes')
    Object.assign(this, data)
  }
}
