// export const API_HOST = '';

export const API_HOST = (() => {
  if (typeof document !== 'undefined') {
    return document.location.protocol + '//' + document.location.host
  } else {
    return 'https://localhost:' + import.meta.env.HTTPS_PORT ? import.meta.env.HTTPS_PORT : '433'
  }
})()

export const API_BASE = '/api'

export const API_ENTRYPOINT = API_HOST + API_BASE

export const AUTH_WATCHER_INTERVAL = 10000

export const DEBUG = import.meta.env.APP_ENV === 'dev'
export const DEBUG_MENU = import.meta.env.APP_ENV === 'dev'

export const LOCALE = 'en'
export const SUCCESS_TIMEOUT = 800
export const ERROR_TIMEOUT = 800
