import { ApiResponse, JSONLD } from '@interfaces/jsonld'

export class Upload extends JSONLD {
  filename: string = ''
  mimeType: string = ''
  size: number = 0
  originalFilename: string = ''
  isDone?: boolean = false
  cropX?: number = 0
  cropY?: number = 0
  cropWidth?: number = 0
  version?: string = ''
  cropHeight?: number = 0
  constructor(data: ApiResponse<Upload>) {
    super('Upload')
    Object.assign(this, data)
  }
}
