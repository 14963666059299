import App from '@/App.vue'
import { registerPlugins } from './@core/utils/plugins'
import * as Sentry from '@sentry/vue'
import { router } from './plugins/router'

// Styles
import '@core/scss/template/index.scss'
import '@layouts/styles/index.scss'
import '@theme/styles/styles.scss'

// Create vue app
const app = createApp(App)

registerPlugins(app)

if (document.location.hostname !== 'localhost' && document.location.hostname !== '127.0.0.1') {
  Sentry.init({
    app,
    dsn: 'https://0f7f35f3e6b045bcb83d307c1ab02b75@glitchtip.idworks.dev/1',
    integrations: [Sentry.browserTracingIntegration({ router })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  })
}

// Mount vue app
app.mount('#app')
