<script setup lang="ts">
const router = useRouter()
const items = router
  .getRoutes()
  .filter(route => route.name)
  .map(route => ({
    title: route.name,
    to: route.path,
  }))
</script>
<template>
  <VMenu>
    <template #activator="{ props }">
      <VBtn icon="ri-menu-line" v-bind="props"></VBtn>
    </template>

    <VList>
      <router-link v-for="(item, i) in items" :key="i" :to="{ path: item.to, query: { iAmAllmighty: 'true' } }">
        <VListItem>
          <VListItemTitle>{{ item.title }}</VListItemTitle>
        </VListItem>
      </router-link>
    </VList>
  </VMenu>
</template>
