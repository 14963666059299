import { User } from '@interfaces/user'
import { defineStore } from 'pinia'
import { AUTH_WATCHER_INTERVAL } from '@/config'
import { useApi, api } from '@/utils/api'
import { useAbilityStore } from '@/stores/ability'
import { toast } from 'vuetify-sonner'

export const useSessionStore = defineStore('session', () => {
  const router = useRouter()

  const user: Ref<User | undefined> = ref()
  const hubURL: Ref<URL | undefined> = ref()

  const getUserCurrent = useApi(() => api<User>({ id: 'api/users/current' }))

  let loginWatcher: ReturnType<typeof setTimeout> | undefined

  function managerWatcher() {
    loginWatcher && clearTimeout(loginWatcher)
    if (user.value) {
      loginWatcher = setTimeout(() => {
        refresh()
      }, AUTH_WATCHER_INTERVAL)
    }
  }

  const isLoggedIn = computed(() => user.value && user.value?.id)

  async function refresh() {
    await getUserCurrent
      .call()
      .then(({ data }) => {
        setUser(data)
      })
      .catch(e => {
        if ((e as Error).message === 'Access denied') {
          setUser()
        } else
          toast.error((e as Error).message, {
            prependIcon: 'ri-error-warning',
          })
      })
  }

  const setUser = (userNew?: User) => {
    if (userNew) {
      user.value = userNew
      managerWatcher()
    } else {
      user.value = undefined
      clearTimeout(loginWatcher)
    }
    const ability = useAbilityStore()

    ability.defineAbility(userNew)
  }
  const setValue = (key: string, value: string) => {
    setUser({ ...user.value, [key]: value } as User)
  }
  let isLogoutGracefully = false
  const logoutGracefully = () => {
    isLogoutGracefully = true
    setUser()
  }

  watch(
    user,
    (newUser, oldUser) => {
      if (!newUser && oldUser) {
        if (!isLogoutGracefully) {
          toast.error('You have been logged out', {
            prependIcon: 'ri-close-line',
          })
          router.push({ path: '/login', query: { redirect: router.currentRoute.value.fullPath } })
        } else {
          router.push({ path: '/login' })
          isLogoutGracefully = false
        }
      }
    },
    { deep: true },
  )

  if (window.user) {
    if (!user.value) setUser(window.user as User)
  }

  return {
    user,
    hubURL,
    isLoggedIn,
    refresh,
    setUser,
    setValue,
    logoutGracefully,
  }
})
