import { AccessRights } from '@/interfaces/accessRights'
import { Content } from '@/interfaces/content'
import { WorkflowState } from '@/interfaces/workflowState'
import { ApiResponse, JSONLD } from '@interfaces/jsonld'
import { Participation } from '@interfaces/participation'

export class ContentCollection extends JSONLD {
  subject: string = ''
  name: string = ''
  workflowState?: WorkflowState
  accessRights?: AccessRights
  project: string = ''
  contents: Content[] = []
  parent?: ContentCollection
  children: ContentCollection[] = []
  participations: Participation[] = []
  //eslint-disable-next-line
  metaData: Record<string, any> = {}

  constructor(data: ApiResponse<ContentCollection>) {
    super('ContentCollection')
    Object.assign(this, data)
    this.metaData = Array.isArray(data?.metaData) ? {} : data?.metaData || {}
  }
}
