import { Transition } from '@/interfaces/types'
import { ApiResponse, JSONLD } from '@interfaces/jsonld'

export class WorkflowTask<T extends JSONLD> extends JSONLD {
  description: string = ''
  subject?: T | JSONLD
  link?: string
  transition?: Transition | JSONLD

  parent?: JSONLD

  constructor(data: ApiResponse<WorkflowTask<T>>) {
    super('WithWorkflowState')

    Object.assign(this, data)
  }
}
