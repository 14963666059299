import { JSONLD } from '@interfaces/jsonld'
export class AccessRights extends JSONLD {
  id: number = 0
  rights: string[] = []
  subjectId: number = 0
  subjectType: string = ''
  workflowName: string = ''

  constructor(data?: Partial<AccessRights>) {
    super('AccessRights')
    Object.assign(this, data)
    this.id = data?.id || Number(data?.['@id']) || 0
  }
}
