import { UserRoles } from '@/interfaces/types'
import { JSONLD } from '@interfaces/jsonld'

export class UserGroup extends JSONLD {
  name: string = ''
  roles: UserRoles[] = []
  constructor(data: Partial<UserGroup>) {
    super('UserGroup')
    Object.assign(this, data)
  }
}
