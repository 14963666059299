import { JSONLD } from '@interfaces/jsonld'

export class Role extends JSONLD {
  name: string = ''
  description?: string
  constructor(data: Partial<Role>) {
    super('Role')
    Object.assign(this, data)
  }
}
