import type { App } from 'vue'
import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router'
import { routes } from './routes'
import { DEBUG } from '@/config'
import { useAbilityStore } from '@/stores/ability'
import { useSessionStore } from '@/stores/session'
import { i18n } from '@/plugins/language/i18n'

export const router = createRouter({
  history: createWebHistory(),
  routes: [...routes],
})

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
  const key = to.meta.key
  document.title =
    (key ? i18n.global.t(`pages.${key}.pageTitle`) : (to.name as string)) +
    ' - ' +
    i18n.global.t('applicationName', { count: 2 })

  const { can } = useAbilityStore()
  useSessionStore()
  if (to.query.iAmAllmighty === 'true' && DEBUG) {
    next()
  } else if (can('passwordChange')) {
    next('/reset-password')
  } else if (can('visitRegular') && to.meta.requiresUnauth) {
    next('/welcome')
  } else if (can('visitUnauth') && to.meta.requiresAuth) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (!can('visitAdmin') && to.meta.requiresAdmin) {
    next('/405')
  } else {
    next()
  }
})

export default function (app: App) {
  app.use(router)
}
