import { ApiResponse, JSONLD } from '@/interfaces/jsonld'
import { ParticipantInvitation } from '@/interfaces/participantInvitation'
import { Project } from '@/interfaces/project'
import { User } from '@/interfaces/user'

export class Participant extends JSONLD {
  user?: User
  fullName: string = ''
  email: string = ''
  invitation?: ParticipantInvitation
  roles: string[] = []
  project: Project | string = ''

  constructor(data?: ApiResponse<Participant>) {
    super('Participant')
    Object.assign(this, data)
  }
}
