import { AccessRights } from '@/interfaces/accessRights'
import { createObject } from '@/interfaces/helpers'
import { WorkflowState } from '@/interfaces/workflowState'
import { ApiResponse, JSONLD } from '@interfaces/jsonld'

export class WithWorkflowState<T extends JSONLD> extends JSONLD {
  subject: T
  workflowState: WorkflowState
  accessRights: AccessRights

  constructor(data: ApiResponse<WithWorkflowState<T>>) {
    super('WithWorkflowState')
    Object.assign(this, data)
    this.subject = createObject<T>(data.subject)
    this.workflowState = new WorkflowState(data.workflowState)
    this.accessRights = new AccessRights(data.accessRights)
  }
}
