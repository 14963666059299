import qs from 'qs'
import { NotFoundError, InternalServerError, UnauthorizedError } from '@/utils/error'
import { API_BASE, API_ENTRYPOINT } from '@/config'
import { useSessionStore } from '@/stores/session'
import { ApiResponse, ApiResponses, JSONLD } from '@/interfaces/jsonld'
import { createObject, isJSONLD, objectFactory } from '@/interfaces/helpers'

const MIME_TYPE = 'application/ld+json'

export function genId(collection: string, id: string) {
  return API_BASE + '/' + collection + '/' + id
}

export async function api<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Omit<RequestInit, 'body'> & { query?: Record<string, string>; id: string; body?: Record<string, any> },
): Promise<T> {
  // await delay(2000)
  const headers = new Headers(options.headers)
  let body

  if (options.body) {
    body = JSON.stringify(options.body)
  }
  if (headers.get('Accept') === null) {
    headers.set('Accept', MIME_TYPE)
  }
  if (options.body && !(options.body instanceof FormData) && headers.get('Content-Type') === null) {
    headers.set('Content-Type', MIME_TYPE)
  }
  if (options.body && options.method === 'PATCH') {
    headers.set('Content-Type', 'application/merge-patch+json')
  }
  let id = options.id
  if (options.query) {
    const queryString = qs.stringify(options.query)
    id = `${options.id}?${queryString}`
  }

  return fetch(new URL(id, API_ENTRYPOINT), { ...options, headers, body, mode: 'cors' }).then(async response => {
    let data
    try {
      data = await response.json()
    } catch (e) {
      data = {}
    }
    const sessionStore = useSessionStore()
    sessionStore.hubURL = extractHubURL(response)
    if (response.ok && !data?.error) {
      if (data?.login === 'failed') throw new TwoFactorError()
      return data as T
    } else {
      if (response.status === 404) throw new NotFoundError(data)
      else if (response.status === 500) throw new InternalServerError(data)
      else if (response.status === 401) throw new UnauthorizedError(data)
      else if (response.status === 400) throw new BadRequestError(data)
      else {
        throw new OtherError(data)
      }
    }
  })
}

//eslint-disable-next-line
export function useApi<T extends JSONLD>(apiCall: (...args: any[]) => Promise<ApiResponse<T>>) {
  type Args = Parameters<typeof apiCall>
  const status = ref('idle')
  const error = ref('')
  const data: Ref<T> = ref({}) as Ref<T>
  const before: ((...args: Args) => void)[] = []
  const after: ((arg: T, ...args: Args) => void)[] = []
  let callArgs: Args = [] as Args
  async function call(...args: Args) {
    callArgs = args
    status.value = 'fetching'
    error.value = ''
    for (const fn of before) {
      await fn(...args)
    }

    try {
      data.value = await apiCall(...args).then(response => {
        if (isJSONLD(response)) return createObject<T>(response as ApiResponse<JSONLD>) as T
        else return response
      })
      for (const fn of after) await fn(data.value as T, ...args)
      status.value = 'success'
      return { data: data.value, status: status.value }
    } catch (e) {
      error.value = (e as Error).message
      status.value = 'error'
      throw e
    }
    // return { data: data.value, error: error.value, statue: status.value }
  }
  function reCall() {
    call(...callArgs)
  }
  function reset() {
    status.value = 'idle'
    data.value = {} as T
  }
  function prependBefore(fn: (...args: Args) => void) {
    before.unshift(fn)
  }
  function appendAfter(fn: (arg: T, ...args: Args) => void) {
    after.push(fn)
  }
  return { status, data, error, call, reset, prependBefore, appendAfter, reCall }
}
//eslint-disable-next-line
export function useApiCollection<T extends JSONLD>(apiCall: (...args: any[]) => Promise<ApiResponses<T>>) {
  type Args = Parameters<typeof apiCall>
  const status = ref('idle')
  const error = ref('')
  const data: Ref<T[]> = ref([])
  const before: ((...args: Args) => void)[] = []
  const after: ((arg: T[], ...args: Args) => void)[] = []
  let callArgs: Args = [] as Args
  function call(...args: Args) {
    data.value = []
    callArgs = args
    return doFetch(...args)
  }
  function reCall() {
    return doFetch(...callArgs)
  }
  async function doFetch(...args: Args) {
    status.value = 'fetching'
    error.value = ''
    for (const fn of before) {
      await fn(...args)
    }

    try {
      data.value = await apiCall(...args).then(response => {
        return objectFactory<T>(response)
      })

      for (const fn of after) await fn(data.value as T[], ...args)
      status.value = 'success'
      return { data: data.value, status: status.value }
    } catch (e) {
      error.value = (e as Error).message
      status.value = 'error'
      throw e
    }
  }
  function reset() {
    status.value = 'idle'
    data.value = []
  }
  function prependBefore(fn: (...args: Args) => void) {
    before.unshift(fn)
  }
  function appendAfter(fn: (arg: T[], ...args: Args) => void) {
    after.push(fn)
  }
  return { status, data, error, call, reset, prependBefore, appendAfter, reCall }
}

//eslint-disable-next-line
export function useApiObj<T extends unknown>(apiCall: (...args: any[]) => Promise<T>) {
  type Args = Parameters<typeof apiCall>
  const status = ref('idle')
  const error = ref('')
  const data: Ref<T | Record<string, never>> = ref({})
  const before: ((...args: Args) => void)[] = []
  const after: ((arg: T, ...args: Args) => void)[] = []
  async function call(...args: Args) {
    status.value = 'fetching'
    error.value = ''
    for (const fn of before) {
      await fn(...args)
    }

    try {
      data.value = await apiCall(...args).then(response => {
        return response
      })
      for (const fn of after) await fn(data.value as T, ...args)
      status.value = 'success'
      return { data: data.value, status: status.value }
    } catch (e) {
      error.value = (e as Error).message
      status.value = 'error'
      throw e
    }
  }
  function reset() {
    status.value = 'idle'
    data.value = {}
  }
  function prependBefore(fn: (...args: Args) => void) {
    before.unshift(fn)
  }
  function appendAfter(fn: (arg: T, ...args: Args) => void) {
    after.push(fn)
  }
  return { status, data, error, call, reset, prependBefore, appendAfter }
}
