import { UserRoles } from '@/interfaces/types'
import { UserGroup } from '@/interfaces/userGroup'
import { ApiResponse, JSONLD } from '@interfaces/jsonld'

export interface UserShort extends JSONLD {
  '@type': 'UserShort'
  email?: string
  firstName?: string
  lastName?: string
  company?: string
}
export class User extends JSONLD {
  email: string = ''
  roles: UserRoles[] = []
  firstName: string = ''
  lastName: string = ''
  company: string = ''
  department: string = ''
  userGroups: (UserGroup | string)[] = []
  needsPasswordChange: boolean = false
  readonly totp: boolean = false
  constructor(data: ApiResponse<User>) {
    super('User')

    Object.assign(this, data)
    this.userGroups = data.userGroups.map((group: UserGroup | string) => new UserGroup(group as UserGroup))
  }
}
